/* @import url(https://fonts.googleapis.com/css?family=Raleway:400, 700, 800);
*, */
*:after,
*:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* color: #F5B320; */
}

html {
  height: 100%;
}

#app {
  height: 100%;
}

main {
  height: 100%;
  padding: 3em 2em;
  text-align: center;
  background: #b4bad2;
  overflow: auto;
}

.menu-res {
  /* position: fixed; */
  top: 0;
  right: 0;
  z-index: 999;
  width: 100%;
  /* background: #fff; */
  /* box-shadow: 0px 10px 20px 0px #00000040; */
  /* height: 70px; */
}

.bm-overlay {
  top: 0;
  position: absolute !important;
  right: 0
}

.description {
  max-width: 20em;
  margin: 1.2em auto 1em;
  line-height: 1.3em;
  font-weight: 400;
}

.demo-buttons {
  font-size: 1em;
  max-width: 1200px;
  margin: 2em auto 3em;
  padding: 0 10em;
}

.demo-buttons a {
  display: inline-block;
  margin: 0.75em;
  padding: 1.35em 1.1em;
  width: 15em;
  background: #fffce1;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 800;
  border-top-left-radius: 20px 50px;
  border-top-right-radius: 20px 50px;
  border-bottom-right-radius: 20px 50px;
  border-bottom-left-radius: 20px 50px;
  cursor: pointer;
}

.demo-buttons a.current-demo {
  background: #c94e50;
  color: #fffce1;
}

.side-button {
  display: inline-block;
  width: 5em;
  height: 2.5em;
  line-height: 2.5em;
  cursor: pointer;
  background: #fffce1;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.8em;
  font-weight: 800;
}

.side-button.left {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.side-button.right {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.side-button.active {
  background: #c94e50;
  color: #fffce1;
}

.show .dp-menu::after {
  transform: rotate(180deg);
}

.dp-menu, .dp-menu:hover, .dp-menu.show, .dp-menu:first-child:active {
  background-color: #F5F5F5;
  color: #000;
  border: unset;
  padding: 15px 30px;
  border-radius: 8px;
  margin: 0 0 0 auto;
}

.dropdown-menu.show {
  border: unset;
  box-shadow: 0 3px 10px -6px #2E3192;
  padding: 5px;
  width: 100%;
}

@media screen and (max-width: 40em) {
  main {
    font-size: 80%;
  }

  .demo-buttons {
    max-width: 900px;
    padding: 0 2em;
  }
  .show {
    display: block;
}
}

.bm-burger-button {
  position: absolute;
  width: 35px;
  height: 35px;
  left: 36px;
  top: 18px;
  border-radius: 3px;
}

.bm-burger-button button:focus {
  /* outline: 2px solid #c94e50; */
  outline-offset: 8px;
  opacity: 0;
}

.bm-burger-button button:focus+span span.bm-burger-bars {
  /* background-color: #c94e50; */
}

.right .bm-burger-button {
  left: initial;
  right: 20px;
  /* background-color: #fff; */
}

.bm-burger-bars {
  background: #fff;
  height: 3px !important;
  z-index: 9;
  left: 7px !important;
  width: 20px;
}

.bm-burger-button span {
  display: block;
  height: 20px;
  position: relative;
  top: 8px;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-menu a {
  color: #2F2F2F;
}

.bm-menu a:hover,
.bm-menu a:focus {
  color: #c94e50;
}

.bm-item-list a {
  padding: 0.8em;
}

.bm-item-list a span {
  margin-left: 10px;
  font-weight: 700;
}

.bm-item:focus {
  outline: none;
}

.menu-1 .bm-cross {
  background: #bdc3c7;
}

.menu-1 .bm-menu {
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.menu-1 .bm-cross-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 2px;
}

.menu-1 .bm-cross-button button:focus+span span.bm-cross {
  background-color: #c94e50;
}

.menu-2 .bm-cross {
  background: #999;
}

.menu-2 .bm-menu {
  padding: 3.4em 1em 0;
}

.menu-2 a {
  padding: 1em;
}

.menu-2 i {
  font-size: 1.7em;
  vertical-align: middle;
  color: #282a35;
}

.menu-2 .bm-cross-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 2px;
}

.menu-2 .bm-cross-button button:focus+span span.bm-cross {
  background-color: #c94e50;
}

.menu-3 .bm-cross {
  background: #888;
}

.menu-3 .bm-menu {
  padding: 2em 1em;
  font-size: 1.15em;
}

.menu-3 i {
  opacity: 0.5;
}

.menu-3 span {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.75em;
}

.menu-3 .bm-cross-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 2px;
}

.menu-3 .bm-cross-button button:focus+span span.bm-cross {
  background-color: #c94e50;
}

.menu-4 .bm-cross {
  background: #888;
}

.menu-4 h2 {
  margin: 0 auto;
  padding: 2em 1em;
  color: rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}

.menu-4 h2 i {
  margin-left: 0.2em;
}

.menu-4 h2 span {
  font-size: 1.6em;
  font-weight: 700;
}

.menu-4 a {
  padding: 1em;
  text-transform: uppercase;
  transition: background 0.3s, box-shadow 0.3s;
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}

.menu-4 a span {
  letter-spacing: 1px;
  font-weight: 400;
}

.menu-4 a:hover,
.menu-4 a:focus {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
  color: #b8b7ad;
}

.menu-4 .bm-cross-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 2px;
}

.menu-4 .bm-cross-button button:focus+span span.bm-cross {
  background-color: #c94e50;
}

#slide .bm-cross,
#stack .bm-cross,
#reveal .bm-cross {
  background: #bdc3c7;
}

#slide .bm-menu,
#stack .bm-menu,
#reveal .bm-menu {
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

#slide .bm-cross-button button:focus,
#stack .bm-cross-button button:focus,
#reveal .bm-cross-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 2px;
}

#slide .bm-cross-button button:focus+span span.bm-cross,
#stack .bm-cross-button button:focus+span span.bm-cross,
#reveal .bm-cross-button button:focus+span span.bm-cross {
  background-color: #c94e50;
}

#bubble .bm-cross,
#push .bm-cross {
  background: #999;
}

#bubble .bm-menu,
#push .bm-menu {
  padding: 3.4em 1em 0;
}

#bubble a,
#push a {
  padding: 1em;
}

#bubble i,
#push i {
  font-size: 1.7em;
  vertical-align: middle;
  color: #282a35;
}

#bubble .bm-cross-button button:focus,
#push .bm-cross-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 2px;
}

#bubble .bm-cross-button button:focus+span span.bm-cross,
#push .bm-cross-button button:focus+span span.bm-cross {
  background-color: #c94e50;
}

#elastic .bm-cross {
  background: #888;
}

#elastic .bm-menu {
  padding: 2em 1em;
  font-size: 1.15em;
}

#elastic i {
  opacity: 0.5;
}

#elastic span {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.75em;
}

#elastic .bm-cross-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 2px;
}

#elastic .bm-cross-button button:focus+span span.bm-cross {
  background-color: #c94e50;
}

#scaleDown .bm-cross,
#scaleRotate .bm-cross,
#pushRotate .bm-cross,
#fallDown .bm-cross {
  background: #888;
}

#scaleDown h2,
#scaleRotate h2,
#pushRotate h2,
#fallDown h2 {
  margin: 0 auto;
  padding: 2em 1em;
  color: rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}

#scaleDown h2 i,
#scaleRotate h2 i,
#pushRotate h2 i,
#fallDown h2 i {
  margin-left: 0.2em;
}

#scaleDown h2 span,
#scaleRotate h2 span,
#pushRotate h2 span,
#fallDown h2 span {
  font-size: 1.6em;
  font-weight: 700;
}

#scaleDown a,
#scaleRotate a,
#pushRotate a,
#fallDown a {
  padding: 1em;
  text-transform: uppercase;
  transition: background 0.3s, box-shadow 0.3s;
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
}

#scaleDown a span,
#scaleRotate a span,
#pushRotate a span,
#fallDown a span {
  letter-spacing: 1px;
  font-weight: 400;
}

#scaleDown a:hover,
#scaleRotate a:hover,
#pushRotate a:hover,
#fallDown a:hover,
#scaleDown a:focus,
#scaleRotate a:focus,
#pushRotate a:focus,
#fallDown a:focus {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
  color: #b8b7ad;
}

#scaleDown .bm-cross-button button:focus,
#scaleRotate .bm-cross-button button:focus,
#pushRotate .bm-cross-button button:focus,
#fallDown .bm-cross-button button:focus {
  outline: 2px solid #c94e50;
  outline-offset: 2px;
}

#scaleDown .bm-cross-button button:focus+span span.bm-cross,
#scaleRotate .bm-cross-button button:focus+span span.bm-cross,
#pushRotate .bm-cross-button button:focus+span span.bm-cross,
#fallDown .bm-cross-button button:focus+span span.bm-cross {
  background-color: #c94e50;
}

ul.menu-m {
  padding: 0px;
  margin: 0;
}

ul.menu-m li {
  list-style: none;
  text-align: left;
  padding: 5px 0px 5px 0px;
  /* border-bottom: 1px solid #E4E2DD; */
}

ul.menu-m li a {
  text-decoration: none;
  /* font-size: 14px; */
  padding: 5px 0;
  color: #000;
  /* font-family: "Bodoni MT"; */
}

ul.menu-m li a:hover {
  color: #000;
  background-color: #F5F5F5;
}

ul.menu-m li:last-child {
  border-bottom: unset;
}

.bg-menu-m {
  /* background-image: url('../img/bg-menu.jpg'); */
  background-color: rgba(0,0,0,.3);
  top: 0;
  position: absolute !important;
  right: 0;
}

.menu-open .submenu {
  display: block;
  padding: 0;
}

.menu-open ul.submenu li {
  border-bottom: unset;
  padding: 0;
}

.menu-open ul.submenu li a {
  padding-left: 30px;
  display: block;
  color: #000;
}

.menu-open .nav-link .arrow90 {
  transform: rotate(0deg);
}

.arrow90 {
  transform: rotate(-90deg);
}
#react-burger-menu-btn{
  z-index: 999;
}