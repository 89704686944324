@media screen and (max-width: 1280px) {
    .fc .fc-daygrid-day-frame{
        background-size: 15%;
    }
    .fc .fc-daygrid-day-number {
        font-size: 12px;
    }

    .fc-daygrid-day-frame .box-popover {
        padding: 2px !important;
    }

    .font-res-topic {
        font-size: 10px;
    }

    .font-res-text {
        font-size: 8px !important;
    }
}

@media screen and (max-width: 1024px) {
    .btn-register {
        padding: 12px 30px;
    }

    .font-10 {
        font-size: 10px !important;
    }

    .font-11 {
        font-size: 11px !important;
    }

    .font-12 {
        font-size: 12px !important;
    }

    .font-14 {
        font-size: 14px !important;
    }

    .font-18 {
        font-size: 16px !important;
    }

    .font-20 {
        font-size: 16px !important;
    }

    .font-22 {
        font-size: 14px !important;
    }

    .font-24 {
        font-size: 16px !important;
    }

    .font-26 {
        font-size: 18px !important;
    }

    .font-28 {
        font-size: 20px !important;
    }

    .font-30 {
        font-size: 22px !important;
    }

    .font-32 {
        font-size: 24px !important;
    }

    .font-42 {
        font-size: 34px !important;
    }

    .font-50 {
        font-size: 30px !important;
    }

    .font-60 {
        font-size: 30px !important;
    }

    .font-100 {
        font-size: 60px !important;
    }

    .font-res-12 {
        font-size: 12px !important;
    }

    .font-res-14 {
        font-size: 14px !important;
    }

    ul.menu-start li a {
        font-size: 14px;
        padding: 12px 10px;
    }
    /* .fc .fc-view-harness{
        min-height: 500px !important;
    } */
    .fc-myCustomButton-button {
        width: 420px;
        height: 16px;
    }
    .custom-table-list tbody tr td {
        font-size: 10px;
    }
    body {
        font-size: 14px;
    }
    .modal-md-xl-res .modal-dialog{
        max-width: 85% !important;
    }
    
}

@media screen and (max-width: 768px) {}

@media screen and (max-width: 490px) {
    /* .fc .fc-view-harness{
        height: 500px !important;
    } */
    .modal-md-xl-res .modal-dialog {
        max-width: 95% !important;
        margin: 0 auto;
    }
    .font-res-topic {
        font-size: 8px;
    }

    .font-res-text {
        font-size: 6px !important;
    }
    .fc .fc-toolbar-title {
        font-size: 18px;
    }
    .fc-myCustomButton-button{
        width: 170px;
    }
    .fc-next-button, .fc-prev-button {
        width: 30px !important;
        height: 30px !important;
    }
    h2 {
        font-size: 18px;
    }

    .calc-res {
        height: calc((92vh - 4rem) - 3px) !important
    }

    .img-res-close {
        width: 30px;
    }

    .box-res-m {
        height: auto !important;
        margin-top: 70px !important;
    }

    .select-custom {
        padding: 8px;
    }

    .custom-table-list tbody tr td {
        font-size: 14px;
    }

    .btn-border-b {
        padding: 9px 0;
        font-size: 15px;
    }
    .font-size-20 {
        font-size: 15px;
    }
    .btn-register {
        padding: 8px 30px;
        font-size: 15px;
    }

    .box-index {
        margin-top: 70px;
    }

    .font-10 {
        font-size: 10px !important;
    }

    .font-11 {
        font-size: 11px !important;
    }

    .font-12 {
        font-size: 12px !important;
    }

    .font-14 {
        font-size: 14px !important;
    }

    .font-18 {
        font-size: 16px !important;
    }

    .font-20 {
        font-size: 16px !important;
    }

    .font-22 {
        font-size: 14px !important;
    }

    .font-24 {
        font-size: 16px !important;
    }

    .font-26 {
        font-size: 18px !important;
    }

    .font-28 {
        font-size: 20px !important;
    }

    .font-30 {
        font-size: 22px !important;
    }

    .font-32 {
        font-size: 24px !important;
    }

    .font-42 {
        font-size: 34px !important;
    }

    .font-50 {
        font-size: 30px !important;
    }

    .font-60 {
        font-size: 30px !important;
    }

    .font-100 {
        font-size: 60px !important;
    }

    .font-res-12 {
        font-size: 12px !important;
    }

    .font-res-14 {
        font-size: 14px !important;
    }

    ul.menu-start li a {
        font-size: 14px;
        padding: 12px 10px;
    }
    .regis-res-box{
        height: calc((100vh - 10rem) - 0px) !important;
    }
    .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
        min-height: unset
    }
    .fc .fc-daygrid-day-frame {
       min-height: 90px;
    }
    .logo-res{
        height: 50px !important;
    }
    .iconuser-res{
        height: 15px;
    }
    .font-res-10{
        font-size: 10px;
    }
}