@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.spinner-container {
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    width: 100%;
    z-index: 9;
    left: 0;
    top: 0;
    /* border-radius: 60px; */
}

.loading-spinner {
    width: 60px;
    height: 60px;
    border: 8px solid #f3f3f3;
    /* Light grey */
    border-top: 8px solid #00879E;
    /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
    position: absolute;
    top: 46%;
    left: 46%;
    transform: translate(-50%, -50%);
}

body {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 300 !important;
    background-color: #E9E9E9
}

.font-size-10 {
    font-size: 10px;
}

.font-size-12 {
    font-size: 12px;
}

.font-size-13 {
    font-size: 13px;
}

.font-size-14 {
    font-size: 14px;
}

.font-size-16 {
    font-size: 16px;
}

.font-size-18 {
    font-size: 18px;
}

.font-size-20 {
    font-size: 20px;
}

.font-size-22 {
    font-size: 22px;
}

.font-size-24 {
    font-size: 24px;
}

.font-size-26 {
    font-size: 26px;
}

.font-size-28 {
    font-size: 28px;
}

.font-size-30 {
    font-size: 30px;
}

.font-size-32 {
    font-size: 32px;
}

.font-size-34 {
    font-size: 34px;
}

.font-size-36 {
    font-size: 39px;
}

.font-size-38 {
    font-size: 38px;
}

.font-size-40 {
    font-size: 40px;
}

.font-weight-300 {
    font-weight: 300;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-500 {
    font-weight: 500;
}

.font-weight-600 {
    font-weight: 600;
}
.font-weight-700 {
    font-weight: 700;
}

.cursor-pointer {
    cursor: pointer;
}

.color-white {
    color: #fff;
}

.color-green {
    color: #0A4225;
}

.color-green-fill {
    color: #28903B;
}

.color-gray {
    color: #7D7D7D;
}

.btn-defult {
    text-decoration: none;
    transition: 0.3s;
}

.btn-defult:hover {
    color: #fff;
    opacity: .7;
}

.btn-submit {
    color: #fff;
    background: #2E3192;
    border-radius: 18px;
    border-radius: 27px;
    padding: 12px 0;
    transition: 0.3s;
}

.btn-submit:hover {
    color: #fff;
    background: #2E3192;
    box-shadow: 0px 5px 15px -5px #2E3192;
}

.btn-register {
    color: #fff;
    background: #00879E;
    border-radius: 18px;
    border-radius: 27px;
    padding: 12px 100px;
    transition: 0.3s;
}

.btn-register:hover {
    color: #fff;
    background: #00879E;
    box-shadow: 0px 5px 15px -5px #00879E;
}

.btn-reset {
    color: #fff;
    background: #FFA800;
    border-radius: 18px;
    border-radius: 27px;
    padding: 12px 0;
    transition: 0.3s;
}

.btn-reset:hover {
    color: #fff;
    background: #FFA800;
    box-shadow: 0px 5px 15px -5px #FFA800;
}

.btn-submit-del {
    color: #fff;
    background: #D01900;
    border-radius: 18px;
    border-radius: 27px;
    padding: 12px 0;
    transition: 0.3s;
}

.btn-submit-del:hover {
    border: 1px solid #D01900;
    background: #D01900;
    color: #fff;
    box-shadow: 0px 5px 15px -5px #D01900;
}

.btn-de-del {
    color: #fff;
    background: #D4D4D4;
    border-radius: 18px;
    border-radius: 27px;
    padding: 12px 0;
    transition: 0.3s;
}

.btn-de-del:hover {
    border: 1px solid #D4D4D4;
    background: #D4D4D4;
    color: #fff;
    box-shadow: 0px 5px 15px -5px #D4D4D4;
}

.a-default {
    text-decoration: none;
    color: #212529;
    transition: 0.3s;
}

.btn-login {
    background-color: #F5F5F5;
    text-decoration: none;
    color: #212529;
    transition: 0.3s;
    border-radius: 8px;
}

.btn-login:hover {
    background-color: #F5F5F5;
}

.btn-logout {
    text-decoration: none;
    color: #212529;
    transition: 0.3s;
    border-radius: 8px;
    background-color: #2E3192;
    color: #fff !important;
    display: block;
    padding: 12px 20px !important;
}

.btn-logout:hover {
    background-color: #2E3192 !important;
    color: #fff !important;
}

.a-default:hover {
    text-decoration: none;
    /* opacity: .6; */
    color: #212529;
}

.btn-default {
    border-radius: 18px;
    border-radius: 27px;
    padding: 12px 0;
    transition: 0.3s;
}

.btn-default:hover {
    opacity: .7;
}

.btn-border {
    border: 1px solid #28903B;
    border-radius: 18px;
    border-radius: 27px;
    padding: 12px 0;
    transition: 0.3s;
    color: #28903B;
    background-color: unset;
}

.btn-border:hover {
    color: #fff;
    background: #28903B;
    box-shadow: 0px 5px 15px -5px #28903B;
}

.btn-border-b {
    border: 1px solid #2E3192;
    border-radius: 27px;
    padding: 12px 0;
    transition: 0.3s;
    color: #2E3192;
    background-color: unset;
}

.btn-border-b:hover {
    color: #fff;
    background: #2E3192;
    box-shadow: 0px 5px 15px -5px #2E3192;
}

.btn-border-b:hover img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%) hue-rotate(42deg) brightness(111%) contrast(101%);
}

.btn-border-c {
    border: 1px solid #2E3192;
    border-radius: 5px;
    padding: 12px 0;
    transition: 0.3s;
    color: #2E3192;
    background-color: unset;
    cursor: pointer;
}

.btn-border-c:hover {
    color: #fff;
    background: #2E3192;
    box-shadow: 0px 5px 15px -5px #2E3192;
}

.btn-border-c:hover img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%) hue-rotate(42deg) brightness(111%) contrast(101%);
}

.btn-del {
    border: 1px solid #ED1B24;
    border-radius: 18px;
    border-radius: 27px;
    padding: 12px 0;
    transition: 0.3s;
    color: #ED1B24;
    background-color: unset;
}

.btn-del:hover {
    color: #fff;
    background: #ED1B24;
    box-shadow: 0px 5px 15px -5px #ED1B24;
}

.btn-del:hover img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%) hue-rotate(42deg) brightness(111%) contrast(101%);
}

.sh-pass {
    position: absolute;
    right: 25px;
    bottom: 18px;
}

.input-border {
    border-top: unset;
    background: unset;
    border-left: unset;
    border-right: unset;
    box-sizing: unset;
    border-bottom: 1px solid #F2F2F2;
    font-size: 12px;
    width: 100%;
}

.input-border:focus {
    border-color: #0A4225;
    /*    box-shadow: 0 0 0 0.1rem rgb(10 66 37 / 25%);*/
    border-bottom: 1px solid #07331C;
    outline: none;
}

.input-custom {
    /* border-top: unset;
    background: #F5F5F5;
    border-left: unset;
    border-right: unset;
    box-sizing: inherit;
    border-bottom: 1px solid #F2F2F2; */
    padding: 13px;
    border-radius: 8px;
    font-weight: 300;
    /* width: 100%; */
}

.input-custom:focus {
    border-color: #2E3192;
    box-shadow: 0 7px 8px -10px #2E3192;
    /* border-bottom: unset; */
    /* background: #F5F5F5; */
    outline: none;
}

.select-custom {
    /* border-top: unset;
    background-color: #F5F5F5;
    border-left: unset;
    border-right: unset;
    box-sizing: inherit;
    border-bottom: 1px solid #F2F2F2; */
    /* font-size: 12px; */
    padding: 13px;
    /* width: 100%; */
}

.select-custom:focus {
    border-color: #2E3192;
    box-shadow: 0 7px 8px -10px #2E3192;
    /* border-bottom: unset; */
    /* background-color: unset; */
    /* outline: none; */
}

.bg-white {
    background-color: #fff;
}
.spinbox {
    position: absolute;
    right: 5px;
    top: 4px;
}

.btn-uplods {
    background-color: #F5F5F5;
    padding: 22px 40px;
    border-radius: 15px;
    transition: 0.3s;
    font-size: 20px;
    font-weight: 500;

}

.btn-uplods:disabled {
    background-color: #F5F5F5;
    border: unset;
}

.btn-option:disabled {
    background-color: #F5F5F5;
    border: unset;
    padding: 0;
    margin-right: 4px;
    border-radius: 10px;
}


.btn-uplods:hover {
    background-color: #28903B;
    color: #fff;
}

.btn-uplods:hover img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%) hue-rotate(42deg) brightness(111%) contrast(101%);
}

.box-member {
    border-radius: 15px;
    box-shadow: 0px 4px 13px -8px #28903B;
}

.btn-edit img.bg-01 {
    display: block;
    transition: 0.3s;
}

.btn-edit img.bg-02 {
    display: none;
    transition: 0.3s;
}

.btn-edit:hover img.bg-01 {
    display: none;
}

.btn-edit:hover img.bg-02 {
    display: block;
}

.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
    border: unset !important;
}

.box-file {
    cursor: pointer;
    box-shadow: 0px 6px 15px 0px #D9E1DC;
    border-radius: 12px;
    transition: 0.3s;
}

.text-detail {
    color: #D4D4D4;
}

.box-file:hover {
    background-color: #1F90E2;
}

.box-file:hover img.img-folder {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%) hue-rotate(42deg) brightness(111%) contrast(101%);
}

.box-file:hover .text-topic {
    color: #fff;
}

.click-option img.icon1, .click-option:hover img.icon2 {
    display: inline-block;
    transition: 0.3s;
    cursor: pointer;
}

.click-option:hover img.icon1, .click-option img.icon2 {
    display: none;
}

.invalid-feedback-2 {
    /* display: none; */
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: #dc3545;
}

.box-pop {
    right: 0px;
    background: #fff;
    bottom: -90px;
    z-index: 9;
    border-radius: 12px;
    box-shadow: 0px 2px 10px -5px #10693B;
    display: none;
}

.box-pop-sub {
    padding: 10px 15px;
    box-shadow: 0px 2px 10px -5px #10693B;
    border-radius: 12px;
}

.show {
    display: inline-block;
    /* position: relative; */
}

.box-slide-new {
    background-color: #F4CCCC;
    border-radius: 8px;
    padding: 15px;
    text-align: start;
    font-size: 18px;
    display: block;
    text-decoration: none;
    color: #000;
}
.box-slide-new:hover{
    text-decoration: underline;
}

.slick-dots li {
    width: 8px !important;
    height: 8px !important;
    margin: 0 3px !important;
}

.slick-dots li button, .slick-dots li button::before {
    width: 8px !important;
    height: 8px !important;
    background: #2E3192 !important;
    border-radius: 50%;
    opacity: .2;
    content: unset !important;
}

.slick-dots li.slick-active {
    width: 8px !important;
    height: 8px !important;
}

.slick-dots li.slick-active button, .slick-dots li.slick-active button::before {
    opacity: 1;
    width: 8px !important;
    height: 8px !important;
}

.border-loop {
    border-bottom: 1px solid #D9D9D9;
}

.border-loop:last-child {
    border-bottom: unset;
}
.btn-more{
    text-decoration: none;
    color: #2E3192;
    font-weight: 500;
}
.hover-unset:hover{
text-decoration: none;
}
.btn-pdf{
    background-color: #F5F5F5;
    text-decoration: none;
    color: #000;
    padding: 10px 30px;
    border-radius: 7px;
    display: inline-block;
}
.btn-tags{
    text-decoration: none;
    color: #000;
}
.custom-table-list thead tr th {
    padding: 15px 5px;
    border-bottom: 1px solid #DDDDDD;
}

.custom-table-list tbody tr td {
    padding: 15px 5px;
    border-bottom: 4px solid #fff;
    border-top: 4px solid #fff;
}

.custom-table-list .table-gray td {
    background-color: #F5F5F5 !important;
}

.custom-table-list .table-success td {
    background-color: #D5ECD9 !important;
}

.custom-table-list .table-primary td {
    background-color: #E7E7F1 !important;
}

.custom-table-list .table-info td {
    background-color: #E0F2FF !important;
}

.position-absolute-center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.bg-norepeat{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.box-mobile{
    width: 600px;
}
.box-img-shadow{
    -webkit-filter: drop-shadow(0px -5px 15px #39A6BC);
    filter: drop-shadow(0px -5px 15px #39A6BC);
}
.label-custom{
    display: inline-block;
    background: #fff;
    top: -9px;
    left: 15px;
    padding: 0 5px;
    position: absolute;
}